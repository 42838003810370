.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #181717;
}

.home-header {
  width: 100%;
  display: flex;
  max-width: auto;
  box-shadow: 5px 5px 10px 0px #2d2b2b;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  background-color: #2d2b2b;
}

.home-image {
  width: 500px;
  height: 100px;
  object-fit: cover;
}

.home-separator {
  width: 100%;
  height: 1px;
  margin-top: var(--dl-space-space-oneandhalfunits);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  background-color: var(--dl-color-gray-900);
}

.home-hero {
  width: 100%;
  height: 765px;
  display: flex;
  position: relative;
  min-height: 80vh;
  align-items: center;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-threeunits);
  margin-bottom: -96px;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
}

.home-form {
  width: auto;
  height: auto;
  display: flex;
  box-shadow: 5px 5px 10px 0px #2d2b2b;
  border-radius: var(--dl-radius-radius-radius8);
  background-image: radial-gradient(circle at center, rgb(24, 23, 23) 3.00%, rgb(45, 43, 43) 98.00%);
}

.home-container1 {
  flex: 0 0 auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}

.home-text {
  color: var(--dl-color-gray-white);
  align-self: center;
  margin-top: var(--dl-space-space-oneandhalfunits);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.home-text3 {
  color: var(--dl-color-gray-white);
  font-style: normal;
  font-weight: 600;
  margin-left: var(--dl-space-space-unit);
}

.home-textinput {
  align-self: center;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-unit);
}

.home-button {
  color: #000000;
  align-self: center;
  border-width: 0px;
  margin-bottom: var(--dl-space-space-unit);
  background-color: #ffffff;
}

@media(max-width: 991px) {
  .home-hero {
    flex-direction: column;
  }
}

@media(max-width: 767px) {
  .home-header {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }

  .home-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }

  .home-hero {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}

@media(max-width: 479px) {
  .home-header {
    padding: var(--dl-space-space-unit);
  }

  .home-image {
    width: 350px;
  }

  .home-separator {
    margin-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
  }
}

@media(max-width: 390px) {
  .home-hero {
    width: 100%;
    padding: var(--dl-space-space-twounits);
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
}